import axios from "axios";
import qs from "qs";

const {
  REACT_APP_API_URL
} = process.env;

const axiosInstance = axios.create({
  baseURL: REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(
  (request) => {
    const language = "en";
    if (request.method === "get") {
      const suffix = qs.stringify({
        header: {
          lang: language,
          trackId: "Bermuda Tech",
          requestDate: new Date(),
        },
      }, {
        allowDots: true,
      });
      request.url = `${request.url}?${suffix}`;
    } else {
      request.data = {
        ...request.data,
        header: {
          lang: language,
          trackId: "Bermuda Tech",
          requestDate: new Date(),
        },
      };
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const setAuthorizationToken = (token) => {
  if (token) {
    axiosInstance.defaults.headers.common = {
      Authorization: `bearer ${token}`,
    };
  } else {
    delete axiosInstance.defaults.headers.common.Authorization;
  }
};
export default axiosInstance;