import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import swal from "sweetalert";
import api from "../api";
import { localStorageData } from "../utils/helper";

function PaymentResposne() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [ resposneState, setResponseState ] = useState({
        customerPayment: {
            policyNumber: "",
            email: "",
            policyId: "",
            phoneNumber: "",
            paymentDetails: [
                {
                    amount: 0,
                    invoiceId: ""
                }
            ]

        }
    });
    const [isPaymentSuccessState, setPaymentSuccessState] = useState(false);

    useEffect(() => {

        api.getPaymentDetail({ token: searchParams.get('token') })
            .then((res) => {
                if (res.data.isSuccess === true) {
                    setResponseState(res.data);
                    setPaymentSuccessState(true);
                    swal({ title: "Payment successfull!", text: "Your payment of $" + res.data.customerPayment.paymentDetails[0].amount + " is compeleted", icon: "success"});
                }
                else {
                    swal({
                        title: 'Payment failed!',
                        text: 'We apologize for any inconvenience, to proceed with processing your payment please contact an available agent by dialing 1-800-300-0075.',
                        buttons: false,
                        type: "warning",
                        closeOnClickOutside: false
                    });
                }
            })
            .catch((err) => {
                swal({
                    title: 'Payment failed!',
                    text: 'We apologize for any inconvenience, to proceed with processing your payment please contact an available agent by dialing 1-800-300-0075.',
                    buttons: false,
                    type: "warning",
                    closeOnClickOutside: false
                });           
            });
    }, []);

    return (
        isPaymentSuccessState && 
        <section style={{backgroundColor: "#FACC22"}}>
			<table className="pr-wrapper" width="800" align="center">
				<tbody>
					<tr>
						<td><a href="https://www.amaxinsurance.com"><img src="https://www.amaxinsurance.com/content/experience-fragments/amax/us/en/site/header/master/_jcr_content/root/header/logo.coreimg.svg/1611159085541/logo.svg" width="110px" alt="" /></a></td>
					</tr>
					<tr>
						<td><br /></td>
					</tr>
					<tr>
						<td style={{ background: "#C42532", textAlign: "center" }}><h1 style={{ color: "#FFF", display: "inline-block", margin: "-1px 0 -1px 0", padding: "15px 15px",fontSize:"1.5rem" }}>Payment Reciept</h1></td>
					</tr>
					<tr>
						<td><br /><br /></td>
					</tr>
					<tr>
						<td>
							<table width="600" align="center" border="1" class="table table-bordered table-striped" style={{ borderCollapse: "collapse", borderColor: "#eee", textAlign: "left", backgroundColor: "white" }}>
								<tbody>
									<tr>
										<td colSpan="2"><h3 style={{ margin: "0px" }}>Invoice ID:  {resposneState.customerPayment.paymentDetails[0].invoiceId}</h3></td>
									</tr>
									<tr>
										<th>Policy Number</th><td>{resposneState.customerPayment.policyNumber}</td>
									</tr>
									<tr>
										<th>Email</th><td>{resposneState.customerPayment.email}</td>
									</tr>
									<tr>
										<th>Policy ID</th><td>{resposneState.customerPayment.policyId}</td>
									</tr>
									<tr>
										<th>Phone</th><td>{resposneState.customerPayment.phoneNumber}</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
					<tr>
						<td><br /><br /></td>
					</tr>
					<tr>
						<td>
							<table width="600" align="center" border="0" class="table" cellSpacing="2" style={{ textAlign: "left" }} cellPadding="10">
								<tbody>
									<tr>
										<td colSpan="4"><h2 style={{ margin: "0px" }}>Products:</h2></td>
									</tr>
									<tr>
										<th style={{ background: "#C42532",color: "#FFF", border: "1px solid #FFF" }}>Quantity</th><th style={{ background: "#C42532", color: "#FFF", border: "1px solid #FFF" }}>Unit Price</th><th style={{ background: "#C42532", color: "#FFF", border: "1px solid #FFF" }}>Amount</th>
									</tr>
									<tr>
										<td style={{ background: "#eee", border: "1px solid #FFF" }}>1</td><td style={{ background: "#eee", border: "1px solid #FFF" }}>1</td><td style={{ background: "#eee" }}>${resposneState.customerPayment.paymentDetails[0].amount}</td>
									</tr>
									<tr>
										<td colSpan="2"></td><th style={{ background: "#C42532", color: "#FFF", textAlign: "center" }}>Total: ${resposneState.customerPayment.paymentDetails[0].amount}</th>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
				</tbody>
			</table>
		</section>
    )
}

export default PaymentResposne;
