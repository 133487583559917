import React from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { PolicyContextProvider } from './context/PolicyContext';
import Policy from './Pages/Policy'
import { SearchPolicy } from './Pages/SearchPolicy'
import PaymentResposne from './Pages/PaymentResposne';

function App() {
  return (

    <PolicyContextProvider>
      <Router>
        <Routes>

          <Route path="/" element={<SearchPolicy />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/response" element={<PaymentResposne />} />

        </Routes>
      </Router>
    </PolicyContextProvider>
  )
}

export default App