import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { usePolicyContext } from "../context/PolicyContext";
import { localStorageData, showErrorAlert } from "../utils/helper";
import swal from "sweetalert";

function Policy() {
    let navigate = useNavigate();

    const { policyState } = usePolicyContext();

    const [policyDetailState, setPolicyDetailState] = useState({
        carrierPolicyNumber: "",
        policyType: "",
        policyTerm: "",
        coverage: "",
        drivers: [],
        vehicles: []
    });

    useEffect(() => {

        api.getPolicyById({ policyId: policyState.policyId })
            .then((res) => {

                if (res !== undefined) {
                    setPolicyDetailState(res.data);
                    localStorageData.set('policyDetail', res.data);
                }

            })
            .catch((err) => {
                showErrorAlert();
            })

    }, []);

    const handlePayNow = () => {
        api.getToken({ policyId: policyState.policyId})
            .then((res) => {
                window.location.href = res.data.paymentUrl;
                
            })
            .catch((err) => {
                console.log(err.response);
                swal("Payment failed!", "We apologize for any inconvenience, to proceed with processing your payment please contact an available agent by dialing 1-800-300-0075.", "warning");
            });
    }

    return (
        <>
            {" "}
            <div className="container">
                <div className="row justify-content-center mt-3">
                    <div className="col-md-8 d-flex justify-content-between align-items-center my-3 pb-3 border-bottom">
                        <h4 className="p-0 m-0"> My Policies</h4>
                        <p className="pointer p-0 m-0 c-red">
                            <b>
                                {/* <i className="fas fa-search pe-2"></i>Find My Policies */}
                            </b>
                        </p>
                    </div>
                    <div className="col-md-8 m-0 ">
                        <div className="mb-4 d-flex flex-column justicy-content-center align-items-center">

                            <div className="row w-100 justify-content-center">
                                <div className="col-md-6 col-sm-12 p-0 m-0">
                                    <div className="policyCard" style={{backgroundColor: "white"}}>
                                        <div className="expireFlag bg-red">
                                            <i className="far fa-calendar-times c-white"></i>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                <b>Auto</b>
                                            </div>
                                            <div className="col-md-12 pt-2">
                                                <b className="c-red f-25 text-center d-block">
                                                    Monthly Payment : ${policyState.monthlyPayment}
                                                </b>
                                                <p>Named Insured : {policyState.applicantName}</p>
                                                {/*<p>Policy Id : {policyState.policyId}</p>*/}
                                                <p>Policy Number : {policyState.policyNumber}</p>
                                                <p>Expire Date : {policyState.expireDate && policyState.expireDate.substring(0,10)}</p>
                                                <p>Effective Date : {policyState.effectiveDate && policyState.effectiveDate.substring(0,10)}</p>
                                                {/*<p>Last Payment Amount : ${policyState.lastPaymentAmount}</p>*/}
                                                {/*<p>Total premimium : ${policyState.initialPremium}</p>*/}
                                                <p>Payment Due Date : {policyState.paymentDueDate && policyState.paymentDueDate.substring(0,10)}</p>
                                                {/*<p>Last Payment Date : {policyState.lastPaymentDate && policyState.lastPaymentDate.substring(0,10)}</p>*/}
                                                <button
                                                    type="button"
                                                    className="c-btn-red my-2"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#policy-details"
                                                >
                                                    Policy Detail
                                                </button>
                                                {policyState.monthlyPayment && policyState.isPayable && <button type="button" className="c-btn-green" onClick={handlePayNow}>
                                                    Pay Now
                                                </button>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal fade"
                id="policy-details"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabindex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="policy-detailsLabel">
                                Policy detail
                            </h1>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <h5 className="d-flex justify-content-center"></h5>
                            <ul className="no-list-style featureList">
                                <li>
                                    <div>
                                        <i className="dot"></i>Policy Number
                                    </div>
                                    <div>{policyDetailState.carrierPolicyNumber}</div>
                                </li>
                                <li>
                                    <div>
                                        <i className="dot"></i>Policy Type
                                    </div>
                                    <div>{policyDetailState.policyType}</div>
                                </li>
                                <li>
                                    <div>
                                        <i className="dot"></i>Term
                                    </div>
                                    <div>{policyDetailState.policyTerm}</div>
                                </li>{" "}
                                <li>
                                    <div>
                                        <i className="dot"></i>Coverage
                                    </div>
                                    <div>{policyDetailState.coverage}</div>
                                </li>
                                <br />
                                <li>
                                    <div>
                                        <i className="dot"></i>Cars
                                    </div>
                                </li>

                                {policyDetailState.vehicles && policyDetailState.vehicles.map((car) => {
                                    return (
                                        <li>
                                            <div>
                                                <i className="dot d-none"></i>
                                            </div>
                                            <div>{car.year} | {car.make} {car.model}</div>
                                        </li>
                                    );
                                })}

                                <li>
                                    <div>
                                        <i className="dot"></i>Drivers
                                    </div>
                                </li>
                                {policyDetailState.drivers && policyDetailState.drivers.map((driver) => {
                                    return (
                                        <li>
                                            <div>
                                                <i className="dot d-none"></i>
                                            </div>
                                            <div>{driver.firstName} {driver.lastName} {driver.age}</div>
                                        </li>
                                    );
                                })}

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Policy;
