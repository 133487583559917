import React, {
    createContext,
    useContext,
    useState
} from "react";

const PolicyContext = createContext();

export const PolicyContextProvider = ({
    children
}) => {
    const [policyState, setPolicyState] = useState({});

    const updatePolicy = (data) => {
        setPolicyState(data);
    };

    return ( <
        PolicyContext.Provider value = {
            {
                policyState,
                updatePolicy
            }
        } >
        {
            children
        } </PolicyContext.Provider>
    );
};

export const usePolicyContext = () => {
    const context = useContext(PolicyContext);

    if (context === undefined) {
        throw new Error("usePolicyContext was used outside of its provider");
    }

    return context;
};

export default PolicyContext;