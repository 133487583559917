import moment from "moment";
import * as _ from "lodash";
import { setAuthorizationToken } from "./axiosInstance";
import api from "../api";
import swal from "sweetalert";

// localstorage belli bir formatta data yazar ve okur
export const localStorageData = {
  prefix: "amax",
  get: (item) => {
    const localData = JSON.parse(
      localStorage.getItem(`${localStorageData.prefix}-${item}`)
    );
    if (localData) return localData;
    localStorage.removeItem(`${localStorageData.prefix}-${item}`);
    return false;
  },
  set: (name, data, expiration) => {
    const allData = {
      data,
      creation: moment(),
      expiration:
        moment.unix(expiration).toISOString() || moment().add(1, "month"),
    };

    return new Promise((resolve, reject) => {
      if (!_.isEmpty(localStorageData.prefix) && !_.isEmpty(name)) {
        localStorage.setItem(
          `${localStorageData.prefix}-${name}`,
          JSON.stringify(allData)
        );

        const parsed = JSON.parse(
          localStorage.getItem(`${localStorageData.prefix}-${name}`)
        );

        resolve({
          status: "success",
          name,
          data: {
            data: parsed.data,
            expiration: parsed.expiration,
            creation: parsed.creation,
          },
        });
      } else {
        reject(new Error(`${name} has not created!`));
      }
    });
  },
  delete: (item) =>
    new Promise((resolve) => {
      if (localStorage.getItem(`${localStorageData.prefix}-${item}`)) {
        localStorage.removeItem(`${localStorageData.prefix}-${item}`);
        resolve(`${item} has been deleted!`);
      } else {
        // eslint-disable-next-line no-console
        console.error(`${item} has not found!`);
      }
    }),
  clearExpired: () => {
    // clear expired data
    Object.keys(localStorage).reduce((total, item) => {
      let data;

      try {
        data = JSON.parse(localStorage[item]);

        if (moment(data.expiration).format() < moment().format()) {
          localStorage.removeItem(item);
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log("localstorage uzerinde gecersiz kayit tespit edildi");
      }
      return total;
    }, []);
    // clear expired data
  },
};

export const checkToken = () => {
  let token = localStorageData.get("token");
  if (!token) {
    return false;
  }
  setAuthorizationToken(token.data);

  return true;
};

export const showErrorAlert = () => {
  swal("Payment failed!", "We apologize for any inconvenience, to proceed with processing your payment please contact an available agent by dialing 1-800-300-0075.", "warning");
};

export const getAuthToken = ({email, phone, dob}, RecaptchaToken) => {
  api
    .loginWithToken({
      isSkipLogin: true,
      email: email,
      phone: phone,
      dob: dob
    }, RecaptchaToken)
    .then((res) => {
      localStorageData.set("token", res.data.token);
      setAuthorizationToken(res.data.token);
    });
};
