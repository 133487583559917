import axiosInstance from "./utils/axiosInstance";

axiosInstance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  return Promise.reject(error);
});
export const client = axiosInstance;

const api = {
  login: (data) =>
    client({
      method: "POST",
      url: `/account/login`,
      data,
    }).then((res) => res),
  loginWithToken: (data, RecaptchaToken) =>
    client({
      method: "POST",
      url: `account/loginWithToken`,
      data,
      headers: {
        RecaptchaToken
      },
    }).then((res) => res),
  searchPolicy: (data) =>
    client({
      method: "POST",
      url: `/policy/searchCustomerPolicy`,
      data,
    }).then((res) => res),
  getPolicyById: (data) =>
    client({
      method: "GET",
      url: `/policy/getCustomerPolicyById`,
      params: data,
    }).then((res) => res),
  getToken: (data) =>
    client({
      method: "POST",
      url: `/makepayment/gettoken`,
      data,
    }).then((res) => res),
  getPolicyPayment: (data) =>
    client({
      method: "GET",
      url: `/policy/getCustomerPolicyPaymentsById`,
      params: data,
    }).then((res) => res),
  getPaymentDetail: (data) => 
  client({
    method: "POST",
    url: `/makepayment/getPaymentDetail`,
    data,
  }).then((res) => res)
};

export default api;