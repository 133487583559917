import React, { useEffect, useRef, useState } from "react";
import { isEmpty } from "lodash";
import api from "../api";
import { usePolicyContext } from "../context/PolicyContext";
import { useNavigate } from "react-router-dom";
import { setAuthorizationToken } from "../utils/axiosInstance";
import { localStorageData } from "../utils/helper";
import swal from "sweetalert";


export const SearchPolicy = () => {
	let navigate = useNavigate();

	const { policyState, updatePolicy } = usePolicyContext();
	const [errors, setErrors] = useState({});
	const [isSearching, setIsSearching] = useState(false);

	const [state, setState] = useState({
		email: "",
		phone: "",
		dob: "",
		policyNumber: "",
	});

	const SITE_KEY = process.env.REACT_APP_SITE_KEY;

	useEffect(() => {
		const loadScriptByURL = (id, url, callback) => {
		  const isScriptExist = document.getElementById(id);
	 
		  if (!isScriptExist) {
			var script = document.createElement("script");
			script.type = "text/javascript";
			script.src = url;
			script.id = id;
			script.onload = function () {
			  if (callback) callback();
			};
			document.body.appendChild(script);
		  }
	 
		  if (isScriptExist && callback) callback();
		}
	 
		// load the script by passing the URL
		loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
		  console.log("Script loaded!");
		});
	  }, []);

	const handleChange = (evt) => {
		const value = evt.target.value;
		setState({
			...state,
			[evt.target.name]: value,
		});

		setErrors((prevState) => {
			return { ...prevState, [evt.target.nameme]: null };
		});
	};

	const validateSearchFields = () => {
		if (isEmpty(state.policyNumber) || isEmpty(state.dob) || isEmpty(state.email) || isEmpty(state.phone)) {
			return "error";
		}
	}

	const handleSearch = () => {
		if (isEmpty(state.policyNumber) || isEmpty(state.dob) || isEmpty(state.email) || isEmpty(state.phone)) {
			return "error";
		}
		setIsSearching(true);

		window.grecaptcha.ready(() => {
			window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
				submitData(token);
			});
		  });
	};

	const submitData = (token) => {
		api
		.loginWithToken({
			isSkipLogin: true,
			email: state.email,
			phone: state.phone,
			dob: state.dob
		}, token)
		.then((res) => {
			localStorageData.set("token", res.data.token);
			setAuthorizationToken(res.data.token);

			api.searchPolicy({
				policyNumber: state.policyNumber,
				// dob: state.dob + "T00:00:00",
			})
				.then((res) => {
					setIsSearching(false);

					if (!res.data.isPayable) {
						swal("Payment failed!", "We apologize for any inconvenience, to proceed with processing your payment please contact an available agent by dialing 1-800-300-0075.", "warning");
						return;
					}

					updatePolicy({...res.data, email: state.email, phone: state.phone});
					navigate("/policy");
				})
				.catch((err) => {
					setIsSearching(false);
					console.log(err);
					// swal("Policy!", err.response.data.message, "error");
            swal("Payment failed!", "We apologize for any inconvenience, to proceed with processing your payment please contact an available agent by dialing 1-800-300-0075.", "warning");
				});
		}).catch((err) => {
			setIsSearching(false);
            swal("Payment failed!", "We apologize for any inconvenience, to proceed with processing your payment please contact an available agent by dialing 1-800-300-0075.", "warning");
		});
	}

	return (
		<div className="container">
			<div className="row d-flex justify-content-center mt-3">
				<div className="col-md-8 d-flex justify-content-between align-items-center mb-3  pb-3 border-bottom">
					<h4 className="p-0 m-0">Search Policies</h4>
					<p className="pointer p-0 m-0 c-red">
						<b>
							{/* <i className="fas fa-arrow-left me-2"></i>Back */}
						</b>
					</p>
				</div>
				<div className="row justify-content-center">
					<div className="col-md-5 text-center mt-2">
						<div className="row p-0">
							<div className="col-md-12">
								<h5>Please enter the information on your policy</h5>
							</div>
							<div className="col-md-12">
								<div className="input-group c-input mt-3">
									<div className="w-100">
										<small className="input-label">Policy Number *</small>
										<input
											className="form-control "
											type="text"
											placeholder=""
											aria-label="Policy Number *"
											name="policyNumber"
											value={state.policyNumber}
											onChange={handleChange}
										/>
									</div>
									{errors.policyNumber && (<small style={{ marginLeft: "20px", color: "red" }}>{errors.policyNumber}</small>)}
								</div>
								<div className="input-group c-input mt-2">
									<div className="w-100">
										<small className="input-label">Email *</small>
										<input
											className="form-control "
											type="text"
											name="email"
											placeholder=""
											aria-label="Email *"
											value={state.email}
											onChange={handleChange}
										/>
									</div>
									{errors.email && (<small style={{ marginLeft: "20px", color: "red" }}>{errors.email}</small>)}
								</div>
								<div className="input-group c-input mt-2">
									<div className="w-100">
										<small className="input-label">Phone *</small>
										<input
											className="form-control "
											type="text"
											name="phone"
											aria-label="Phone *"
											placeholder=""
											value={state.phone}
											onChange={handleChange}
										/>
									</div>
									{errors.phone && (<small style={{ marginLeft: "20px", color: "red" }}>{errors.phone}</small>)}
								</div>
								<div className="input-group c-input mt-2 input-active">
									<div className="w-100">
										<small className="input-label">Date of Birth *</small>
										<input
											className="form-control "
											type="date"
											name="dob"
											placeholder=""
											value={state.dob}
											onChange={handleChange}
										/>
									</div>
									{errors.dob && (<small style={{ marginLeft: "20px", color: "red" }}>{errors.dob}</small>)}
								</div>

								<button
									type="button"
									className="c-btn-red mt-2 text-center"
									onClick={handleSearch}
									disabled={isSearching}
								>
									<i className="fas fa-search c-white"></i> Search

									{isSearching && (
									<i
											class="fas fa-circle-notch fa-spin"
											style={{ marginLeft: "5px" }}
										></i>
									)}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
